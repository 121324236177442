<template>
    <v-container>
		<AddTask />
        <ModeratorTasksTable />
    </v-container>  
</template>

<script>
import ModeratorTasksTable from '@/components/templates/ModeratorTasksTable'
import AddTask from '@/components/molecules/AddTask'
export default {
    components: {
		ModeratorTasksTable,
		AddTask
    }
}
</script>